import { red } from "@material-ui/core/colors";

export const styles = {
    appBar: {
        backgroundColor: "#343A40",
        height: "50px",
        position: "sticky",
        top: 0,
        '& .MuiToolbar-regular': {
            minHeight: "50px"
        }
    },
    
    name: {
        marginRight: "15px"
    },
    link: {
        textTransform: "unset",
        color: "#a5a5a5",
        margin: "0 20px",
        textDecoration: "unset"
    },

    
    textField: {
        margin: "0 15px 15px",
    },

    dropdown: {
        width:"150px"
    },
    topState:{
        width:"150px",
        marginRight:"10px",
    },

    selectBox: {
        width:"150px",
    },

    addselectBox:{
        width:"250px",
    },
 
    search: {
        display:"flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        margin:"10px 0 20px",
    },
    
    detail: {
        margin: "0",
        width: "100%",
        wordBreak: "break-word",
        display: "flex",
        borderBottom: "1px solid #eee",
        padding: "5px 0",
        flexDirection: "row",
    },
    
    detailInfo: {
        backgroundColor: "orange",
        borderRadius: "0",
        textDecoration: "none",
        fontSize: "15px",
        fontWeight: "500",
        padding: "3px 10px",
        color: "white",
        position:"absolute",
        right: "0",
        top: "0px",
        float: "right"
    },

    edit: {
        backgroundColor: "orange",
        borderRadius: "0",
        textDecoration: "none",
        fontSize: "15px",
        fontWeight: "500",
        padding: "3px 10px",
        color: "white",
        position:"absolute",
        right: "115px",
        top: "0px",
        float: "right"
    },
    publish: {
        backgroundColor: "#0062cc",
        "&:hover": {
            backgroundColor: "#0062cc",
            opacity: 0.8
        }
    },
    delete: {
        backgroundColor: "#DD4145",
        "&:hover": {
            backgroundColor: "#DD4145",
            opacity: 0.8
        }
    },
    update: {
        backgroundColor: "#64A845",
        "&:hover": {
            backgroundColor: "#64A845",
            opacity: 0.8
        }
    },
    upvote: {
        marginTop: "10px",
        backgroundColor: "#28A745",
        "&:hover": {
            backgroundColor: "#218838",
            borderRadius:"50px",
        }
    },
    downvote: {
        marginTop: "10px",
        backgroundColor: "#DD4145",
        "&:hover": {
            backgroundColor: "#D7262B",
            borderRadius:"50px",
        }
    },
    markspam: {
        marginTop: "10px",
        backgroundColor: "#0062cc",
        "&:hover": {
            backgroundColor: "#004188",
            borderRadius:"50px",
        }
    },
    categoryButton: {
        marginTop: "10px",
        backgroundColor: "#747474",
        "&:hover": {
            backgroundColor: "#218838",
            borderRadius:"50px",
        }
    },
    categoryButtonSelected: {
        marginTop: "10px",
        backgroundColor: "#218838",
        borderRadius:"50px !important",
        boxShadow:"0 0 10px #218838 !important",
        "&:hover": {
            backgroundColor: "#218838",
            borderRadius:"50px",
        }
    },
    logo: {
        
        
        height: "35px",
        overflow: "hidden"
    },
    buttonWrapper: {
        marginTop: "20px"
    },
    button: {
        marginRight: "13px",
        color: "white",
        fontSize: "14px",
        textTransform: "none",
        padding: "6px 10px",
        marginBottom: "0px",
        borderRadius: "0",
    },
    removeAll: {
        marginTop: "20px"
    },
    covidinfo: {
        width: "100%",
    },

    label:{
        minWidth:"175px",
    },

    covidinfoDetail: {
        width: "75%",
        margin: "40px auto",
        border: "1px solid #ddd",
        padding: "20px",
        boxShadow: "0 0 9px #ddd"
   },


};

