import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { styles } from "./css-common"

import AddCovidInfo from "./components/add-covidinfo.component";
import CovidInfo from "./components/covidinfo.component";
import Terms from "./components/terms.component";
import CovidInfosList from "./components/covidinfos-list.component";
import VaccineInfosList from "./components/vaccine-list.component";

import { AppBar, Toolbar, Typography, withStyles, BottomNavigation } from '@material-ui/core';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {auth: {loggedin: false, user: undefined}};
  }

  async componentDidMount() {
    const resp = await fetch('/api/auth/me');
    const json = await resp.json();
    this.setState({auth: json});
  }

  render() {
    const { classes } = this.props
    const auth = this.state.auth;
    return (
      <div>
        <AppBar className="appbar" position="static">
          <Toolbar className="topbar">

            {/* <div className={`mobilelogo ${classes.logo}`}>
              <a href="https://channelier.com/" target="_blank"><img src="/logo.png" alt=""></img></a>
              <i class="fa fa-chevron-down"></i>
            </div> */}

            <div className="custom-logo">
              <h2><a href="http://covidcare.channelier.com/">Covid Care</a></h2>
            </div>

            <Link to={"/covidinfos"} className={`toplink ${classes.link}`}>
              <Typography variant="body2">
                List
              </Typography>
            </Link>

            <Link to={"/add"} className={`toplink ${classes.link}`}>
              <Typography variant="body2">
                Add
            </Typography>
            </Link>

            <Link to={"/vaccine-centers"} className={`toplink ${classes.link}`}>
              <Typography variant="body2">
                Vaccine Centers
              </Typography>
            </Link>

            <div style={{flex:1}} />
            <Login auth={auth} />
          </Toolbar>
        </AppBar>

        <Switch>
          <Route exact path={["/", "/covidinfos"]} component={(props) => <CovidInfosList {...props} auth={auth} />} />
          <Route exact path="/add" component={AddCovidInfo} />
          <Route exact path="/terms" component={Terms} />
          <Route path="/edit/:id" component={(props) => <AddCovidInfo {...props} auth={auth} />} />
          <Route path="/covidinfos/:id" component={(props) => <CovidInfo {...props} auth={auth} />} />
          <Route exact path="/vaccine-centers" component={VaccineInfosList} />
        </Switch>


         <BottomNavigation className="footerbar">
           

            <div className="about">
              <a href="https://channelier.com/" target="_blank">About us</a>
            </div>


            <div className="floating-div">
             <div className="inner-buttons">
                <a className="float_btn" href="https://forms.gle/fmc4xkA6ExSHzRT8A" target="_blank"><i class="fa fa-users"></i> Volunteer with us</a>
                <Link to={"/add"} className="float_btn">
                <i class="fa fa-user-plus"></i>  Add more data
              </Link>
              </div>

            </div>


            
            <Link to={"/terms"} className={`toplink ${classes.link}`}>
              <Typography variant="body2">
                Terms and Conditions
            </Typography>
          </Link>

          <a target="_blank" href={"https://twitter.com/CovidcareC"} className="footerimg">
            <img src="/twitter.svg" width={30} height={30} ></img>
          </a>

            <p className="supported">Supported by team   <a href="https://channelier.com/" target="_blank"> Channelier </a> </p>

            </BottomNavigation>
      </div>
    );
  }
}

const Login = (props) => {
  if (props.auth.loggedin){
    return (<>Hello {props.auth.user.displayName}</>)
  }else {
    return (<a style={props.style} href={"/api/auth/facebook"}><img src="/fbl.png" /></a>)
  }
}

function NoMatch() {
  return (
    <div>
      <h3>
        Page not found.
      </h3>
    </div>
  );
}

export default withStyles(styles)(App);