import http from "../http-common";

class CovidInfoDataService {
  getAll() {
    return http.get("/info.list");
  }

  get(id) {
    return http.get(`/info.get/${id}`);
  }

  create(data) {
    return http.post("/info.create", data);
  } 

  edit(data) {
    return http.post("/info.edit", data);
  }  

  update(id, data) {
    return http.put(`/covidinfos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/covidinfos/${id}`);
  }

  deleteAll() {
    return http.delete(`/covidinfos`);
  }

  findByFilter(categoryId, locationId) {
    return http.get(`/info.list/${categoryId}/${locationId}`);
  }

  //categories
  getCategories() {
    return http.get("/category.list");
  }

  //states
  getStates() {
    return http.get("/location.stateList");
  }

  //cities
  getLocations(state) {
    var data = {
        state: state,
    };
    return http.post("/location.cityList", data);
  }

  //upvote
  setUpvote(infoId) {
    var data = {
        infoId: infoId,
    };
    return http.post("/info.upVote", data);
  }

  //downvote
  setDownvote(infoId) {
    var data = {
        infoId: infoId,
    };
    return http.post("/info.downVote", data);
  }

  //markspam
  setMarkSpam(infoId) {
    var data = {
        infoId: infoId,
    };
    return http.post("/info.markSpam", data);
  }

  //vaccine states
  getVaccineStates() {
    return http.get("/vaccinelocation.stateList");
  }

  //vaccine districts
  getVaccineDistricts(stateId) {
    return http.get("/vaccinelocation.districtList/"+stateId);
  }

  //vaccine centers list
  findByVaccineFilter(districtId) {
    return http.get(`/vaccinecenters.list/${districtId}`);
  }
}

export default new CovidInfoDataService();