import React, { Component } from "react";
import CovidInfoDataService from "../services/covidinfo.service";
import { Link } from "react-router-dom";
import "../App.css";

import { styles } from "../css-common"
import { Button, Grid, ListItem, withStyles, InputLabel, Select, MenuItem, TextField  } from "@material-ui/core";

class VaccineList extends Component {
  constructor(props) {
    super(props);
    this.onChangeStateId = this.onChangeStateId.bind(this);
    this.onChangeDistrictId = this.onChangeDistrictId.bind(this);
    this.retrieveVaccineInfos = this.retrieveVaccineInfos.bind(this);
    this.refreshList = this.refreshList.bind(this);

    this.state = {
      vaccineinfos: [],
      districts: [],
      states: [],
      districtId: 140,
      stateId: 9
    };
  }

  componentDidMount() {
    this.retrieveStates();
    this.retrieveDistricts();
    this.retrieveVaccineInfos();
  }

  retrieveStates() {
    CovidInfoDataService.getVaccineStates()
      .then((response) => {
        this.setState({
          states: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveDistricts() {
    CovidInfoDataService.getVaccineDistricts(this.state.stateId)
      .then((response) => {
        this.setState({
          districts: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async onChangeStateId(e) {
    await this.setState({
      stateId: e.target.value,
    });

    this.retrieveDistricts();
  }

  async onChangeDistrictId(e) {
    await this.setState({
      districtId: e.target.value,
    });
  }

  retrieveVaccineInfos() {
    CovidInfoDataService.findByVaccineFilter(
      this.state.districtId
    )
      .then((response) => {
        this.setState({
          vaccineinfos: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveVaccineInfos();
  }

  render() {
    const { classes } = this.props;
    const {
      vaccineinfos
    } = this.state;

    return (
      <div className="mainform">
        <Grid container>
          <Grid
            className={`mobile-search ${classes.search}`}
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
          >
            <div className={`mobstate ${classes.topState}`}>
              <InputLabel htmlFor="states" className="searchLabel">
                State
              </InputLabel>
              <Select
                value={this.state.stateId}
                onChange={this.onChangeStateId}
                className={classes.selectBox}
              >
                {this.state.states.map((state) => (
                  <MenuItem value={state.stateId} key={state.stateId}>
                    {state.stateName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={classes.dropdown}>
              <InputLabel htmlFor="locations" className="searchLabel">
                District
              </InputLabel>
              <Select
                value={this.state.districtId}
                onChange={this.onChangeDistrictId}
                className={classes.selectBox}
              >
                {this.state.districts.map((district) => (
                  <MenuItem value={district.districtId} key={district.districtId}>
                    {district.districtName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="search_btn">
            <InputLabel className="searchLabel">
             
              </InputLabel>
            <Button
              type="submit"
              className={`${classes.upvote} searchbtn ${classes.button}`}
              onClick={(e) =>
                this.refreshList()
              }
            >
              Search 
            </Button> 

             <Button
              type="submit"
              className={`${classes.upvote} search-icon ${classes.button}`}
              onClick={(e) =>
                this.refreshList()
              }
            >
              <i class="fa fa-search"></i>
            </Button>
            </div>
          </Grid>

          <Grid item md={12} xs={12}>
              <div className="filter-div">
                <h3>Filters: </h3>

                <div className="filter">
                  <label>Date: </label>
                  <input className="form-control" type="date" id="" name="date"></input>
                </div>
                <div className="filter">
                  <label>Age: </label>
                  <select className="form-control">
                    
                    <option>18+</option>
                    <option>45+</option>
                    <option>55+</option>
                  </select>
                </div>

                <div className="filter">
                  <label>Capacity: </label>
                  <select className="form-control">
                   
                    <option>10+</option>
                    <option>20+</option>
                    <option>40+</option>
                  </select>
                </div>

                <div className="filter">
                  <label>Vaccine: </label>
                  <select className="form-control">
                   
                    <option>Covidshield</option>
                    <option>Covaxin</option>
                  </select>
                </div>

              </div>
          </Grid>


          <Grid item md={12}>
            <h2 className="section-heading">
              Vaccine Centers List ({this.state.vaccineinfos.length})
            </h2>

            <div className="list-group">
              {vaccineinfos &&
                vaccineinfos.map((currentVaccineInfo, index) => (
                  <ListItem
                    className="gridBox"
                    divider
                    button
                    key={index}
                  >
                    <div className={classes.covidinfo}>
                      
                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Name</strong>
                        </label>{" "}
                        {currentVaccineInfo.name}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Address</strong>
                        </label>{" "}
                        {currentVaccineInfo.address}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>District</strong>
                        </label>{" "}
                        {currentVaccineInfo.district_name}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Block</strong>
                        </label>{" "}
                        {currentVaccineInfo.block_name}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Pincode</strong>
                        </label>{" "}
                        {currentVaccineInfo.pincode}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Timing</strong>
                        </label>{" "}
                        {currentVaccineInfo.from} - {currentVaccineInfo.to} 
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Fee</strong>
                        </label>{" "}
                        {currentVaccineInfo.fee_type}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Dates</strong>
                        </label>{" "}
                        </div>


                        
                          
                              <div className="table-responsive">

                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Available Capacity</th>
                                      <th>Min Age Limit</th>
                                      <th>Vaccine</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {currentVaccineInfo.sessions &&
                                 currentVaccineInfo.sessions.map((session, index) => (
                                    <tr>
                                      <td>{" "}  {session.date}{"        "}</td>

                                      <td>{" "}  {session.available_capacity}{"        "}</td>

                                      <td>{" "}  {session.min_age_limit}{"        "}</td>

                                     <td>{" "}  {session.vaccine}{"        "}</td>

                                    </tr>
                                    ))} 
                                  </tbody>
                                </table>


                                {/* <label className="infolabel">
                                  <strong>Date: </strong>
                                </label>{" "}
                                {session.date}{",        "}
                              
                                <label >
                                  <strong>Available Capacity: </strong>
                                </label>{" "}
                                {session.available_capacity}{",        "}
                              
                                <label >
                                  <strong>Min Age Limit: </strong>
                                </label>{" "}
                                {session.min_age_limit}{",        "}
                              
                                <label >
                                  <strong>Vaccine: </strong>
                                </label>{" "}
                                {session.vaccine}{",        "} */}


                              
                                {/* <label >
                                  <strong>Slots: </strong>
                                </label>
                                {session.slots} */}
                              </div>

                                            
                    </div>
                  </ListItem>
                ))}
            </div>
          </Grid>
          {/* <Grid item md={8}>
            {}
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(VaccineList)