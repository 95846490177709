import React, { Component } from "react";
import Helmet from "react-helmet";
import CovidInfoDataService from "../services/covidinfo.service";

import { styles } from "../css-common"
import { TextField, Button, withStyles } from "@material-ui/core";

class CovidInfo extends Component {
  constructor(props) {
    super(props);
    this.getCovidInfo = this.getCovidInfo.bind(this);

    this.state = {
      currentCovidInfo: {},
      shareText:""
    };
  }

  componentDidMount() {
    this.getCovidInfo(this.props.match.params.id);
    if(window.FB){
      window.FB.XFBML.parse();
    }
  }

  getCovidInfo(id) {
    CovidInfoDataService.get(id)
      .then((response) => {
          let textToShare = 
          `Name: ${response.data.response.name}\nMobile: ${response.data.response.mobile}\nAddress: ${response.data.response.address}\nDetails: ${response.data.response.details}\nCategory: ${response.data.response.categoryId}\nCity: ${response.data.response.locationId}\n`;
                           
        this.setState({
          currentCovidInfo: response.data.response,
          shareText:encodeURIComponent(textToShare)
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  upvoteCovidInfo(infoId) {
    CovidInfoDataService.setUpvote(infoId)
      .then((response) => {
        console.log(response);
        alert("Vote Accepted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  downvoteCovidInfo(infoId) {
    CovidInfoDataService.setDownvote(infoId)
      .then((response) => {
        console.log(response);
        alert("Vote Accepted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  markspamCovidInfo(infoId) {
    CovidInfoDataService.setMarkSpam(infoId)
      .then((response) => {
        console.log(response);
        alert("Spam noted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

 

  render() {
    const { currentCovidInfo,shareText } = this.state;
    const { classes, auth } = this.props;
    const url_href = `https://covidcare.channelier.com/covidinfos/${currentCovidInfo._id}`
    return (
      <div>
        {/* <Helmet>
          <meta property="og:title" content={currentCovidInfo.name} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={currentCovidInfo.details} />
          <meta property="og:type" content={currentCovidInfo.categoryId} />
          <meta name="twitter:title" content={currentCovidInfo.name} />
          <meta name="twitter:description" content={currentCovidInfo.details} />
        </Helmet> */}
        {currentCovidInfo ? (
          <div className={classes.covidinfoDetail}>
            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Name:</strong>
              </label>{" "}
              {currentCovidInfo.name}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Mobile:</strong>
              </label>{" "}
              {currentCovidInfo.mobile}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Address:</strong>
              </label>{" "}
              {currentCovidInfo.address}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Details:</strong>
              </label>{" "}
              {currentCovidInfo.details}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Category:</strong>
              </label>{" "}
              {currentCovidInfo.categoryId}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>City:</strong>
              </label>{" "}
              {currentCovidInfo.locationId}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Last Updated Time:</strong>
              </label>{" "}
              {new Date(currentCovidInfo.lastUpdated).toLocaleString()}
            </div>

            <div className={classes.detail}>
              <label className="infolabel">
                <strong>Trust Score:</strong>
              </label>{" "}
              {currentCovidInfo.score}
            </div>

            <Button
              type="submit"
              className={`${classes.upvote} ${classes.button}`}
              onClick={(e) => this.upvoteCovidInfo(currentCovidInfo._id)}
            >
              Resources available
            </Button>
            <Button
              type="submit"
              className={`${classes.downvote} ${classes.button}`}
              onClick={(e) => this.downvoteCovidInfo(currentCovidInfo._id)}
            >
              Not available
            </Button>
            <Button
              type="submit"
              className={`${classes.markspam} ${classes.button}`}
              onClick={(e) => this.markspamCovidInfo(currentCovidInfo._id)}
            >
              Wrong number
            </Button>

            <Button
              type="submit"
              className="back_btn"
              onClick={(e) => this.props.history.goBack()}
            >
              Go to List
            </Button>
            <Button className="whatsapp_btn">
              <a
                href={`https://api.whatsapp.com/send?text=${shareText}`}
                data-action="share/whatsapp/share"
                target="_blank"
              >
                Share via Whatsapp
              </a>
            </Button>
            <Button className="twitter_btn">
              <a
                href={`http://twitter.com/share?text=${shareText}&url=https://covidcare.channelier.com`}
                target="_blank"
              >
                Share Via Twitter
              </a>
            </Button>
            {auth.loggedin && auth.user.type == 0 && (
              <Button type="submit" className="back_btn">
                Block
              </Button>
            )}

            <div className={classes.detail}>
              <div
                className="fb-comments"
                data-href={url_href}
                data-numposts="15"
                data-order-by="time"
              ></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CovidInfo)