import React, { Component } from "react";
import CovidInfoDataService from "../services/covidinfo.service";

import { TextField, Button, withStyles, InputLabel, Select, MenuItem } from "@material-ui/core"
import { styles } from "../css-common"

class AddCovidInfo extends Component {
    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeDetails = this.onChangeDetails.bind(this);
        this.onChangeCategoryId = this.onChangeCategoryId.bind(this);
        this.onChangeStateId = this.onChangeStateId.bind(this);
        this.onChangeLocationId = this.onChangeLocationId.bind(this);
        this.saveCovidInfo = this.saveCovidInfo.bind(this);
        this.newCovidInfo = this.newCovidInfo.bind(this);

        this.state = {
            id: props.match.params.id ? props.match.params.id : null,
            name: "",
            mobile: "",
            address: "",
            details: "",
            categoryId: ["Oxygen"],
            stateId: "Delhi",
            locationId: "Delhi",
            published: false,
            categories: [],
            states: [],
            locations: [],
            msg: "",
            submitted: false
        };
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.getCovidInfo(this.props.match.params.id);
        }
        this.retrieveCategories();
        this.retrieveStates();
        this.retrieveLocations();
    }

    getCovidInfo(id) {
        CovidInfoDataService.get(id)
          .then((response) => { 
            let info = response.data.response;
            this.setState({
                name: info.name,
                mobile: info.mobile,
                address: info.address,
                details: info.details,
                categoryId: [info.categoryId],
                stateId: info.stateId,
                locationId: info.locationId
            });
            this.retrieveLocations();
          })
          .catch((e) => {
            console.log(e);
          });
      }

    retrieveCategories() {
        CovidInfoDataService.getCategories()
          .then(response => {
            this.setState({
              categories: response.data.response
            });
            console.log(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }

    retrieveStates() {
        CovidInfoDataService.getStates()
          .then(response => {
            this.setState({
              states: response.data.response
            });
            console.log(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }

    retrieveLocations() {
        CovidInfoDataService.getLocations(this.state.stateId)
          .then(response => {
            this.setState({
              locations: response.data.response
            });
            console.log(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeMobile(e) {
        this.setState({
            mobile: e.target.value
        });
    }

    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    }

    onChangeDetails(e) {
        this.setState({
            details: e.target.value
        });
    }

    onChangeCategoryId(e) {
        this.setState({
            categoryId: e.target.value
        });
    }

    async onChangeStateId(e) {
        await this.setState({
            stateId: e.target.value
        });
        this.retrieveLocations();
    }

    onChangeLocationId(e) {
        this.setState({
            locationId: e.target.value
        });
    }

    saveCovidInfo() {
        var data = {
            id: this.state.id,
            name: this.state.name,
            mobile: this.state.mobile,
            address: this.state.address,
            details: this.state.details,
            categoryId: this.state.categoryId,
            locationId: this.state.locationId
        };

        if(this.state.id == null){

            CovidInfoDataService.create(data)
                .then(response => {
                    this.setState({
                        msg: response.data.response,
                        submitted: true
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        } else {

            CovidInfoDataService.edit(data)
                .then(response => {
                    this.setState({
                        msg: response.data.response,
                        submitted: true
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        }
    }

    newCovidInfo() {
        this.setState({
            id: null,
            name: "",
            mobile: "",
            address: "",
            details: "",
            published: false,
            msg: "",
            submitted: false
        });
    }

    render() {
        const { classes, auth } = this.props

        return (
            <React.Fragment>
                {this.state.submitted ? (
                    <div className={classes.form}>
                        
                        {this.state.msg ? (
                            <h4>You submitted successfully! {this.state.msg}</h4>
                        ) : (
                            <h4>You submitted successfully!</h4>
                        )}
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={this.newCovidInfo}>
                            Add
                        </Button>
                        
                    </div>
                ) : (
                        <div className={`addform ${classes.form}`}>
                            <div className={`addinput ${classes.textField}`}>
                                <TextField
                                    label="Name" className="addlabel"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChangeName}
                                    required
                                />
                            </div>

                            <div className={`addinput ${classes.textField}`}>
                                <TextField
                                    label="Mobile" className="addlabel"
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={this.onChangeMobile}
                                    required
                                />
                            </div>

                            <div className={`addinput ${classes.textField}`}>
                                <TextField
                                    label="Address" className="addlabel"
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.onChangeAddress}
                                    required
                                />
                            </div>

                            <div className={`addinput ${classes.textField}`}>
                                <TextField
                                    label="Details" className="addlabel"
                                    name="details"
                                    value={this.state.details}
                                    onChange={this.onChangeDetails}
                                    required
                                />
                            </div>

                            <div className={`addpageSelect ${classes.dropdown}`}>
                                <InputLabel htmlFor="categories">Category (Multiple Select)</InputLabel>
                                <Select
                                  multiple={true}
                                  value={this.state.categoryId}
                                  onChange={this.onChangeCategoryId}
                                  className={classes.addselectBox}
                                >
                                  {this.state.categories.map(category => (
                                    <MenuItem value={category.name} key={category.name}>
                                      {category.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                            </div>

                            <div className={`addpageSelect ${classes.dropdown}`}>
                                <InputLabel htmlFor="states">State</InputLabel>
                                <Select
                                  value={this.state.stateId}
                                  onChange={this.onChangeStateId}
                                  className={classes.addselectBox}
                                >
                                  {this.state.states.map(stateId => (
                                    <MenuItem value={stateId} key={stateId}>
                                      {stateId}
                                    </MenuItem>
                                  ))}
                                </Select>
                            </div>

                            <div className={`addpageSelect ${classes.dropdown}`}>
                                <InputLabel htmlFor="locations">City</InputLabel>
                                <Select
                                  value={this.state.locationId}
                                  onChange={this.onChangeLocationId}
                                  className={classes.addselectBox}
                                >
                                  {this.state.locations.map(location => (
                                    <MenuItem value={location.city} key={location.city}>
                                      {location.city}
                                    </MenuItem>
                                  ))}
                                </Select>
                            </div>

                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                className="submitBtn"
                                onClick={this.saveCovidInfo}>
                                Submit
                            </Button>
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AddCovidInfo)