import React, { Component } from "react";
import CovidInfoDataService from "../services/covidinfo.service";
import { Link } from "react-router-dom";
import "../App.css";

import { styles } from "../css-common"
import { Button, Grid, ListItem, withStyles, InputLabel, Select, MenuItem  } from "@material-ui/core";

class CovidInfosList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.onChangeStateId = this.onChangeStateId.bind(this);
    this.onChangeLocationId = this.onChangeLocationId.bind(this);
    this.onChangeCategoryId = this.onChangeCategoryId.bind(this);
    this.retrieveCovidInfos = this.retrieveCovidInfos.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveCovidInfo = this.setActiveCovidInfo.bind(this);
    this.removeAllCovidInfos = this.removeAllCovidInfos.bind(this);
    this.searchTitle = this.searchTitle.bind(this);

    this.state = {
      covidinfos: [],
      categories: [],
      locations: [],
      states: [],
      categoryId: "Oxygen",
      locationId: "Delhi",
      stateId: "Delhi",
      currentCovidInfo: null,
      currentIndex: -1,
      searchTitle: "",
      mobile: [],
    };
  }

  componentDidMount() {
    this.retrieveCategories();
    this.retrieveStates();
    this.retrieveLocations();
    this.retrieveCovidInfos();
  }

  retrieveCategories() {
    CovidInfoDataService.getCategories()
      .then((response) => {
        this.setState({
          categories: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveStates() {
    CovidInfoDataService.getStates()
      .then((response) => {
        this.setState({
          states: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveLocations() {
    CovidInfoDataService.getLocations(this.state.stateId)
      .then((response) => {
        this.setState({
          locations: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  async onChangeStateId(e) {
    await this.setState({
      stateId: e.target.value,
    });

    this.retrieveLocations();
  }

  async onChangeLocationId(e) {
    await this.setState({
      locationId: e.target.value,
    });

    this.retrieveCovidInfos();
  }

  async onChangeCategoryId(categoryId) {
    await this.setState({
      categoryId: categoryId,
    });

    this.retrieveCovidInfos();
  }

  retrieveCovidInfos() {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let regex = new RegExp(expression);
    let address = "";
    CovidInfoDataService.findByFilter(
      this.state.categoryId,
      this.state.locationId
    )
      .then((response) => {
        response.data.response.forEach((element) => {
          if (element.mobile.includes(",")) {
            element.mobile = element.mobile.split(",");
          } else {
            element.mobile = element.mobile.split(" ");
          }
          if (element.address.match(regex)) {
            address = (
              <a target="_blank" href={element.address}>
                {element.address}
              </a>
            );
          } else {
            address = <a target="_blank">{element.address}</a>;
          }
          element.address = address;
        });
        this.setState({
          covidinfos: response.data.response,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveCovidInfos();
    this.setState({
      currentCovidInfo: null,
      currentIndex: -1,
    });
  }

  setActiveCovidInfo(covidinfo, index) {
    this.setState({
      currentCovidInfo: covidinfo,
      currentIndex: index,
    });
  }

  removeAllCovidInfos() {
    CovidInfoDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  searchTitle() {
    CovidInfoDataService.findByTitle(this.state.searchTitle)
      .then((response) => {
        this.setState({
          covidinfos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  upvoteCovidInfo(infoId) {
    CovidInfoDataService.setUpvote(infoId)
      .then((response) => {
        console.log(response);
        alert("Vote Accepted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  downvoteCovidInfo(infoId) {
    CovidInfoDataService.setDownvote(infoId)
      .then((response) => {
        console.log(response);
        alert("Vote Accepted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  markspamCovidInfo(infoId) {
    CovidInfoDataService.setMarkSpam(infoId)
      .then((response) => {
        console.log(response);
        alert("Spam noted");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { classes, auth } = this.props;
    const {
      searchTitle,
      covidinfos,
      currentCovidInfo,
      currentIndex,
    } = this.state;

    return (
      <div className="mainform">
        <Grid container>
          <Grid
            className={`mobile-search ${classes.search}`}
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
          >
            <div className={`mobstate ${classes.topState}`}>
              <InputLabel htmlFor="states" className="searchLabel">
                State
              </InputLabel>
              <Select
                value={this.state.stateId}
                onChange={this.onChangeStateId}
                className={classes.selectBox}
              >
                {this.state.states.map((stateId) => (
                  <MenuItem value={stateId} key={stateId}>
                    {stateId}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={classes.dropdown}>
              <InputLabel htmlFor="locations" className="searchLabel">
                City
              </InputLabel>
              <Select
                value={this.state.locationId}
                onChange={this.onChangeLocationId}
                className={classes.selectBox}
              >
                {this.state.locations.map((location) => (
                  <MenuItem value={location.city} key={location.city}>
                    {location.city}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>

          <Grid item sm={12} xs={12} md={12} xl={12} lg={12}>
            {this.state.categories.map((category, index) => (
              <Button
                type="button"
                className={
                  this.state.categoryId === category.name
                    ? `category-btn ${classes.categoryButtonSelected} category-btn ${classes.button}`
                    : `${classes.categoryButton} category-btn ${classes.button}`
                }
                onClick={(e) => this.onChangeCategoryId(category.name)}
              >
                {category.name}
              </Button>
            ))}
          </Grid>

          <Grid item md={12}>
            <h2 className="section-heading">
              Covid Info List ({this.state.covidinfos.length})
            </h2>

            <div className="list-group">
              {covidinfos &&
                covidinfos.map((currentCovidInfo, index) => (
                  <ListItem
                    className="gridBox"
                    selected={index === currentIndex}
                    onClick={() =>
                      this.setActiveCovidInfo(currentCovidInfo, index)
                    }
                    divider
                    button
                    key={index}
                  >
                    <div className={classes.covidinfo}>
                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Name</strong>
                        </label>{" "}
                        {currentCovidInfo.name}
                        {auth.loggedin && auth.user.type == 0 && (
                          <Link
                            to={"/edit/" + currentCovidInfo._id}
                            className={classes.edit}
                          >
                            Edit
                          </Link>
                        )}
                        <Link
                          to={"/covidinfos/" + currentCovidInfo._id}
                          className={classes.detailInfo}
                        >
                          View Detail
                        </Link>
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Mobile</strong>
                        </label>{" "}
                        <div className="contact-div">
                          {currentCovidInfo &&
                            currentCovidInfo.mobile.map(
                              (singleMobile, index) => (
                                <a href={`tel:${singleMobile}`}>
                                {index !== currentCovidInfo.mobile.length - 1 ?`${singleMobile},`:singleMobile}
                                </a>
                              )
                            )}
                        </div>
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Address</strong>
                        </label>{" "}
                      {currentCovidInfo.address}
                        
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Details</strong>
                        </label>{" "}
                        {currentCovidInfo.details}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Category</strong>
                        </label>{" "}
                        {currentCovidInfo.categoryId}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>City</strong>
                        </label>{" "}
                        {currentCovidInfo.locationId}
                      </div>

                      <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Last Updated Time</strong>
                        </label>{" "}
                        {new Date(
                          currentCovidInfo.lastUpdated
                        ).toLocaleString()}
                      </div>

                      {/* <div className={classes.detail}>
                        <label className="infolabel">
                          <strong>Trust Score</strong>
                        </label>{" "}
                        {currentCovidInfo.score}
                      </div> */}

                      <Button
                        type="submit"
                        className={`${classes.upvote} ${classes.button}`}
                        onClick={(e) =>
                          this.upvoteCovidInfo(currentCovidInfo._id)
                        }
                      >
                        Resources available
                      </Button>
                      <Button
                        type="submit"
                        className={`${classes.downvote} ${classes.button}`}
                        onClick={(e) =>
                          this.downvoteCovidInfo(currentCovidInfo._id)
                        }
                      >
                        Not available
                      </Button>
                      <Button
                        type="submit"
                        className={`${classes.markspam} ${classes.button}`}
                        onClick={(e) =>
                          this.markspamCovidInfo(currentCovidInfo._id)
                        }
                      >
                        Wrong number
                      </Button>
                    </div>
                  </ListItem>
                ))}
            </div>
          </Grid>
          {/* <Grid item md={8}>
            {}
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CovidInfosList)