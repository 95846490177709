import React from "react";


const Terms = () => {
    return (<div className="terms">
    <h1>Terms and Conditions</h1>
    <ol>
        <li>The purpose of this website is to provide a platform for sharing free and ready information to anyone related to availability about Covid related facilities, and should be used for that purpose only. , our intent is not to mislead any user in any way.</li>
        <li>You are responsible for your use of any content you provide, including compliance with applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with others and have verified it. Any use or reliance on any content or materials posted on this platform or obtained by you through this platform is at your own risk. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content or communications posted via this platform or endorse any information expressed via the platform.</li>
        <li>You understand that by using this platform, you may be exposed to content that might be inaccurate, or in some cases, postings that have been mislabeled or are otherwise deceptive. All content is the sole responsibility of the person who originated such content.</li>
        <li>We may not monitor or control the content posted via this platform and, we cannot take responsibility for such content.</li>
        <li>Covidcare.channelier.com attempts to be as accurate as possible. However, it does not warrant that contact or service description or other content is accurate, complete, reliable, current, or error-free.</li>
        <li>Please do not pay any contact source from this website without verifying the service yourself. We do not take any responsibility of any financial transaction happening between the users of this platform in any form.</li>
        <li>We are only trying to provide information; our team doesn’t provide any of the facility hence Under no circumstances we will be responsible for any financial transaction on the name of this platform. please don’t pay anyone on the name of this website.</li>
        <li>Please do not give fake numbers or names, please remember that we are in the middle of the crisis and we want to be truthful to our users. Your support is appreciated</li>
        <li>We are not collecting user’s personal data for any marketing purpose</li>
        <li>By using this website, you are agreeing to above mentioned terms and conditions</li>
    </ol>
    </div>);

}
export default Terms;